<template>
	<div class="container-fluid header">
		<header>
			<nav>
				<div class="container-logo">
					<div class="logo-bg">
						<router-link to="/"><img src="@/assets/images/logo.svg" class="logo ir" alt="Doctor A to Z" /></router-link>
					</div>
				</div>
				<section class="menu" :class="{'active' : openMenu}" id="menu-xs" v-click-outside="onClickOutside">
					<ul class="menu">
						<li @click.prevent="closeMenu">
							<router-link to="/" class="home">{{ $t('Home') }}</router-link>
						</li>
						<li class="submenu dd" :class="{'active': menuSelected === 'about_us', 'on': this.$route.name === 'About'}">
							<a href="javascript:" @click.prevent="toggleSubmenu('about_us')">{{ $t('About Us') }}</a>
							<div class="submenu">
								<ul class="submenu">
									<li @click.prevent="closeMenu">
										<router-link to="/about#vm" v-html="$t('Vision & Mission')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#management" v-html="$t('Management Team')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#docnet" v-html="$t('Our Doctor’s Networks')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#awards" v-html="$t('Awards')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#partnership" v-html="$t('Our Partner')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#career" v-html="$t('Careers')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/about#contact" v-html="$t('Contact Us')"></router-link>
									</li>
								</ul>
							</div>
						</li>
						<li class="submenu dd" :class="{'active' : menuSelected === 'product_service', 'on': ['Products', 'Services'].includes(this.$route.name)}">
							<a href="javascript:" @click.prevent="toggleSubmenu('product_service')">{{ $t('Product & Services') }}</a>
							<div class="submenu">
								<ul class="submenu">
									<li @click.prevent="closeMenu">
										<router-link to="/products" v-html="$t('Our Products')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/products#qna" v-html="$t('FAQs')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/products#testimonial" v-html="$t('Testimonials')"></router-link>
									</li>
								</ul>
							</div>
						</li>
						<li @click.prevent="closeMenu" :class="{'on': ['Individual'].includes(this.$route.name)}">
							<router-link to="/individual">{{ $t('Individual') }}</router-link>
						</li>
						<li class="submenu dd" :class="{'active' : menuSelected === 'corporate', 'on': this.$route.name === 'Corporate'}">
							<a href="javascript:" @click.prevent="toggleSubmenu('corporate')">{{ $t('Corporate') }}</a>
							<div class="submenu">
								<ul class="submenu">
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#hospital" v-html="$t('Hospital & Clinic Solution')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#smart" v-html="$t('Smart City Smart Health Solution')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#insurance" v-html="$t('Insurance Solution')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#employee" v-html="$t('Employees Health Benefit Solution')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#medical" v-html="$t('Medical Tourism & Hospitality Solution')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#qna" v-html="$t('FAQs')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#form" v-html="$t('Request a demo')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/corporate#testimonial" v-html="$t('Testimonials')"></router-link>
									</li>
								</ul>
							</div>
						</li>
						<li class="submenu dd" :class="{'active' : menuSelected === 'investor', 'on': this.$route.name === 'Investor'}">
							<a href="javascript:" @click.prevent="toggleSubmenu('investor')">{{ $t('Investor') }}</a>
							<div class="submenu">
								<ul class="submenu">
									<li @click.prevent="closeMenu">
										<router-link to="/investor#ceo" v-html="$t('CEOs talk')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/investor#team" v-html="$t('Our Team')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/investor#business" v-html="$t('Business Prospect & Expansion')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/investor#traction" v-html="$t('Our Traction & Growth')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/investor#investor" v-html="$t('Our Investor')"></router-link>
									</li>
									<li @click.prevent="closeMenu">
										<router-link to="/investor#request" v-html="$t('Request Pitch Desk')"></router-link>
									</li>
								</ul>
							</div>
						</li>
					</ul>

					<div v-if="$i18n.locale === 'en'" class="lang" :class="{'active' : menuSelected === 'lang'}" @click.prevent="toggleSubmenu('lang')">
						<a href="javascript:" class="en" :class="{'active' : $i18n.locale === 'en'}" @click.prevent="changeLanguage('en')">EN</a>
						<a href="javascript:" class="th" :class="{'active' : $i18n.locale === 'th'}" @click.prevent="changeLanguage('th')">TH</a>
					</div>
					<div v-else class="lang" :class="{'active' : menuSelected === 'lang'}" @click.prevent="toggleSubmenu('lang')">
						<a href="javascript:" class="th" :class="{'active' : $i18n.locale === 'th'}" @click.prevent="changeLanguage('th')">TH</a>
						<a href="javascript:" class="en" :class="{'active' : $i18n.locale === 'en'}" @click.prevent="changeLanguage('en')">EN</a>
					</div>
				</section>
				<div class="container-btn-menu" :class="{'active' : openMenu}">
					<button id="btn-menu" :class="{'active' : openMenu}" @click.prevent="toggleMenu"></button>
				</div>
			</nav>
		</header>
	</div>
</template>


<script>
import ClickOutside from 'vue-click-outside'

export default {
	data: () => ({
		menuSelected: null,
		openMenu: false
	}),
	created() {
		if (localStorage.getItem('lang')) {
			this.$i18n.locale = localStorage.getItem('lang')
		}
	},
	methods: {
		toggleMenu() {
			this.openMenu = !this.openMenu;
		},
		closeMenu() {
			this.menuSelected = null;
			this.openMenu = false;
		},
		toggleSubmenu(hasMenu) {
			if (this.menuSelected === hasMenu) {
				return this.menuSelected = null;
			}
			this.menuSelected = hasMenu;
		},
		changeLanguage(lang) {
			this.$i18n.locale = lang
			localStorage.setItem('lang', lang)
		},
		onClickOutside() {
			this.menuSelected = null;
		}
	},
	directives: {
		ClickOutside
	}
}
</script>