<template>
	<div>
		<div class="container-fluid bg-prefooter py-5">
			<div class="container">
				<div class="row prefooter">
					<div class="col">
						<ul>
							<li class="title text-uppercase" v-html="$t('About Us')"></li>
							<li>
								<router-link to="/about#vm" v-html="$t('Vision & Mission')"></router-link>
							</li>
							<li>
								<router-link to="/about#management" v-html="$t('Management Team')"></router-link>
							</li>
							<li>
								<router-link to="/about#docnet" v-html="$t('Our Doctor’s Networks')"></router-link>
							</li>
							<li>
								<router-link to="/about#awards" v-html="$t('Awards')"></router-link>
							</li>
							<li>
								<router-link to="/about#partnership" v-html="$t('Our Partner')"></router-link>
							</li>
							<li>
								<router-link to="/about#career" v-html="$t('Careers')"></router-link>
							</li>
							<li>
								<router-link to="/about#contact" v-html="$t('Contact Us')"></router-link>
							</li>
						</ul>
					</div>
					<div class="col">
						<ul>
							<li class="title text-uppercase" v-html="$t('Corporate')"></li>
							<li>
								<router-link to="/corporate#hospital" v-html="$t('Hospital & Clinic Solution')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#smart" v-html="$t('Smart City Smart Health Solution')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#insurance" v-html="$t('Insurance Solution')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#employee" v-html="$t('Employees Health Benefit Solution')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#medical" v-html="$t('Medical Tourism & Hospitality Solution')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#qna" v-html="$t('FAQs')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#form" v-html="$t('Request a demo')"></router-link>
							</li>
							<li>
								<router-link to="/corporate#testimonial" v-html="$t('Testimonials')"></router-link>
							</li>
						</ul>
					</div>
					<div class="col">
						<ul>
							<li class="title text-uppercase" v-html="$t('Investor')"></li>
							<li>
								<router-link to="/investor#ceo" v-html="$t('CEOs talk')"></router-link>
							</li>
							<li>
								<router-link to="/investor#team" v-html="$t('Our Team')"></router-link>
							</li>
							<li>
								<router-link to="/investor#business" v-html="$t('Business Prospect & Expansion')"></router-link>
							</li>
							<li>
								<router-link to="/investor#traction" v-html="$t('Our Traction & Growth')"></router-link>
							</li>
							<li>
								<router-link to="/investor#investor" v-html="$t('Our Investor')"></router-link>
							</li>
							<li>
								<router-link to="/investor#request" v-html="$t('Request Pitch Desk')"></router-link>
							</li>
						</ul>
					</div>
					<div class="col">
						<ul>
							<li class="title text-uppercase" v-html="$t('Product & Services')"></li>
							<li>
								<router-link to="/products" v-html="$t('Our Products')"></router-link>
							</li>
							<li>
								<router-link to="/products#qna" v-html="$t('FAQs')"></router-link>
							</li>
							<li>
								<router-link to="/products#testimonial" v-html="$t('Testimonials')"></router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-precontact py-5">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<h3 class="gold" v-html="$t('WORKING HOURS')"></h3>
					</div>
				</div>
				<div class="row">

					<div class="col-md-6 text-center">
						<p class="white" v-html="$t('Monday Friday')"></p>
						<p class="lg white" v-html="$t('900am600pm')"></p>
					</div>
					<div class="col-md-6 text-center">
						<p class="white" v-html="$t('Saturday Sunday')"></p>
						<p class="lg white" v-html="$t('Closed')"></p>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-homecontact py-5">
			<div class="container">
				<div class="row">
					<div class="col-md-6 text-center">
						<h3 class="gold" v-html="$t('VISIT US')"></h3>
						<p class="white" v-html="$t('visit_address')"></p>

						<div class="social d-flex justify-content-between">
							<a href="https://www.facebook.com/DoctorAtoZ" target="_blank" class="fb"></a>
							<a href="https://lin.ee/wQCIo14" target="_blank" class="line"></a>
							<a target="_blank" class="youtube"></a>
							<a href="mailto:service@doctoratoz.co" class="email"></a>
						</div>
						<div class="pt-3 pb-3"><a href="tel:+6628216465" class="btn-tel">+662-821-6465</a></div>
					</div>
					<div class="col-md-6 text-center">
						<form class="fcontact" @submit.prevent="sendContactUs">
							<h3 class="gold" v-html="$t('CONTACT US')"></h3>
							<div class="row no-gutters">
								<div class="col-12"><input type="text" :placeholder="$t('Full Name')" required v-model="model.name" /></div>
								<div class="col-6"><input type="email" :placeholder="$t('Email')" required v-model="model.email" /></div>
								<div class="col-6"><input type="tel" :placeholder="$t('Phone')" required maxlength="10" v-model="model.phone" /></div>
								<div class="col-12"><textarea class="none-resize" :placeholder="$t('Message')" required v-model="model.detail"></textarea></div>
								<div class="col-12 text-right"><input type="submit" :value="$t('SEND')" class="btn-submit" /></div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid copyright">
			<div class="container">
				<div class="row">
					<div class="col-12" v-html="$t('Copyright 2021 All Rights Reserved by DOCTOR A to Z')"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		model: {
			name: '',
			email: '',
			phone: '',
			detail: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Contact us from website Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		async sendContactUs() {
			let html = ''
			html += '<div>Name: ' + this.model.name + '</div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Detail ' + this.model.detail + ' </div><br/><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {

				let url = "/contact";
				await window.axios.post(url, this.model).then(res => {
					if (res.status === 200) {
						alert(this.$t('Contact information has been sent successfully'));
						this.model = {
							name: '',
							email: '',
							phone: '',
							detail: ''
						}
					}
				}).catch(error => {
					console.log(url, error)
				});
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	},
}
</script>