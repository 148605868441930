import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import './assets/stylus'
import './plugins/axios'
import './plugins/global'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app')