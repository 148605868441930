import Vue from 'vue'
import Router from 'vue-router'
import paths from './paths'
import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false });
Vue.use(Router)
const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		} else {
			return { x: 0, y: 0 }
		}
	},
	routes: paths
})

router.beforeEach((to, from, next) => {
	NProgress.start()
	next();
})

router.afterEach(() => {
	NProgress.done()
})
export default router;