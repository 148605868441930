export default [
	{
		path: '*',
		redirect: {
			path: '/404'
		}
	},
	{
		path: '/404',
		name: 'NotFound',
		component: () => import( /* webpackChunkName: "404" */ `@/views/errors/NotFound.vue`)
	},
	{
		path: '/',
		name: 'Home',
		component: () => import( /* webpackChunkName: "home" */ '@/views/Home.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import( /* webpackChunkName: "about" */ '@/views/About.vue')
	},
	{
		path: '/corporate',
		name: 'Corporate',
		component: () => import( /* webpackChunkName: "corporate" */ '@/views/Corporate.vue')
	},
	{
		path: '/investor',
		name: 'Investor',
		component: () => import( /* webpackChunkName: "investor" */ '@/views/Investor.vue')
	},
	{
		path: '/products',
		name: 'Products',
		component: () => import( /* webpackChunkName: "products" */ '@/views/Products.vue')
	},
	{
		path: '/individual',
		name: 'Individual',
		component: () => import( /* webpackChunkName: "individual" */ '@/views/Individual.vue')
	}
]